<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>行业管理规范</h1>
      <p class="up">第一条 【定位及适用范围】</p>
      <p>根据法律规定及Vllshop平台的管理要求，Vllshop平台商在部分行业开展业务时，须满足行业准入及特定经营要求。</p>
      <br>
      <p class="up">第二条【准入标准】</p>
      <p>卖家在网上商店出售以下类别商品时，须向平台提供符合条件的文件。</p>
      <p>具体资质要求如下：</p>
      <p>一、食品行业：</p>
      <p>1.1 酒水饮料</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《食品经营许可证》</p>
      <p>1.2 茶叶</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《食品经营许可证》或《食品生产》</p>
      <p>1.3 水产品（如大闸蟹、帝王蟹、蟹肉等）</p>
      <p>需提交以下资质证明材料：</p>
      <p>《检验检疫条例》、《进出口贸易许可证》、《出口生产企业备案证明》、《印章经营证书》、《食品进出口贸易凭单》</p>
      <p>1.4专利养殖类（如梅花鹿、马鹿、纹蛙、鸵鸟、美洲虎、大东方龟、尼罗鳄、海湾、珍珠鹦鹉等、养殖大鲵）</p>
      <p>需提交以下资质证明材料：</p>
      <p>《水生动物经营许可证》、《水生动物经营许可证》或《水生动物经营利用》</p>
      <p>1.5 食品罐头</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《食品经营许可证》或《食品生产》</p>
      <p>1.6 保健食品/营养食品</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《食品添加剂生产许可证》</p>
      <p>1.7农产品（如花生、玉米、猪肉等牛肉、水果、水产品）</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《水果海关备案证书》、《水果生产企业登记证书》（出口环境登记证书）或《出境水果包装厂登记证书》）《印章检验检疫证书》《出口环境货物交换证》凭单》</p>
      <p>2.医药行业：</p>
      <p>2.1 兽药</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业执照》、《兽药经营许可证》或《兽药生产许可证》</p>
      <p>2.2 医疗器械</p>
      <p>需提交以下资质证明材料：</p>
      <p>《医疗器械经营许可证》、《医疗器械备案凭证》、《医疗器械经营许可证》或《医疗器械经营许可证》</p>
      <p>3、园艺业：</p>
      <p>3.1 植物类</p>
      <p>需提交以下资质证明材料：</p>
      <p>《营业员》、《动植物检验检疫证书》</p>
      <br>
      <p class="up">第三条【经营要求】</p>
      <p>卖家经营期间需观察Vllshop平台的管理要求，为消费者提供良好的相关服务。</p>
      <br>
      <p class="up">第四条【不当行为】</p>
      <p>1、卖家未提交特定行业的资质证明材料而发布销售类商品的，下架不良商品，卖家可在安全的申领中进行。</p>
      <p>2、卖家中心提交的证明材料不全面而发布限售类商品的，下架不良商品，卖家可以在安全的宣传中进行。</p>
      <br>
      <p class="up">第五条【店铺】</p>
      <p>1、提供经核实证明材料的，立即下架一件商品并整合经营。</p>
      <p>2、卖家在行业经营期间违反法律规定，或出现重大问题的法律影响，立即下架商品并联合运营。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndustryRule'
}
</script>

<style scoped>

</style>
